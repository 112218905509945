<template>
  <div>
    <b-modal
      id="modal-kkm"
      size="lg"
      title="Atur Nilai Kriteria Ketuntasan Minimal(KKM)"
      body-class="pt-0"
      hide-footer
    >
      <div class="row">
        <div class="col-md-12">
          <form @submit.stop.prevent="formOnsubmit()">
            <b-form-group
              id="input-group-name"
              label="Nama Mata Pelajaran:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                :value="courseData.name"
                disabled
                placeholder="Nama Mata Pelajaran"
              ></b-form-input>
              <!-- <small class="text-danger">{{ error.name }}</small> -->
            </b-form-group>
            <b-form-group
              id="input-group-score"
              label="Nilai Kriteria Ketuntasan Minimal(KKM):"
              label-for="input-score"
            >
              <b-form-input
                id="input-score"
                v-model="form.score"
                placeholder="Nilai Kriteria Ketuntasan Minimal(KKM) Format"
              ></b-form-input>
              <small class="text-danger">{{ error.score }}</small>
            </b-form-group>
          </form>
          <div class="d-flex justify-content-between border-top pt-10">
            <div>
              <b-button
                type="submit"
                @click.stop.prevent="formOnsubmit()"
                variant="primary"
                >Simpan</b-button
              >
              <b-button
                type="button"
                class="ml-2"
                variant="default"
                @click="cancelSetScore"
              >
                Batal
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    course: Object,
  },

  components: {},
  data() {
    return {
      courseData: [],
      // form
      form: {
        score: "",
        course_id: "",
      },
      error: {
        score: "",
        course_id: "",
      },
    };
  },

  methods: {
    setData() {
      this.form.course_id = this.course.id;
      if(this.course.kkm != '-'){
        this.form.score = this.course.kkm;
      }
      this.courseData = this.course;
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(
        this.form,
        `api/minimum-completeness-criterias/courses/${this.form.course_id}/set-score`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.successSetScore()
      }
    },
    cancelSetScore() {
      this.$emit("cancelSetScore");
      this.resetErrorOnNew()
    //   this.resetFromOnNew()
    },
    successSetScore() {
      this.$emit("successSetScore");
      this.resetErrorOnNew()
      this.resetFromOnNew()
    },
    resetErrorOnNew() {
      let self = this;
      for (const [key, value] of Object.entries(this.error)) {
        self.error[key] = "";
      }
    },
    resetFromOnNew() {
      let self = this;
      for (const [key, value] of Object.entries(this.form)) {
        self.form[key] = "";
      }
    },
  },
  watch: {
    course: function (newVal, oldVal) {
      // watch it
      //("Course changed: ", newVal, " | was: ", oldVal);
      this.setData();
    },
  },
};
</script>

<style>
</style>